import { useState, useEffect, useRef, useContext } from "react";
import LowConfidence from "./Component/lowConfidence/lowConfidence";
import { DeleteAsync, GetAsync, PutAsync } from "../../common/httpRequests";
import { API_GATEWAY } from "src/constants/settings";
import Page from "src/components/Page";
import { Typography, Grid, Box, Tabs, Divider, Chip } from "@mui/material";
import { Button, Tab } from "@mui/material";
import "./style.css";
import { setScreen } from "src/redux/slices/reviewScreen";
import { DocumentFinished } from "../../mixpanel/documentFinished";
import { store } from "../../redux/store";
import Xarrow from "react-xarrows";
import DialogBox from "./Component/dialogBox";
import { Formik, Form, FormikProps } from "formik";
import ItemsTableTable from "./Component/itemsModalTable/preprocessing/Table";
import { partition } from "src/utils/partition";
import { dialogboxStyles } from "./Component/styles";
import useTabs from "src/hooks/useTabs";
import useLocales from "src/hooks/useLocales";
import { COLOR_LIST } from "./hooks/LabelColors";
import PostProcessing from "./Component/processing/PostProcessing";
import PreProcessed from "./Component/processing/PreProcessed";
import {
  getLabeColor,
  applyColorToLabel,
  generateLabels,
} from "./hooks/useLabels";
import XMLDataView from "./Component/dataView/xmlDataView";
import PageDataView from "./Component/dataView/pageDataView";
import ItemsTable from "./Component/itemsModalTable/postProcessing/Table";
import { useErrorTranslation } from "./Component/useErrorMessage";
import { LoadingButton } from "@mui/lab";
import useReviewToolbar from "./hooks/useReviewToolbar";
import ReviewToolbarPanel from "./Component/reviewToolbar";
import { createOcrBoundingBoxes } from "./Component/dataView/BoundingBox";
import { Navigate, useLocation, useNavigate } from "react-router";
import { VariantType, useSnackbar } from "notistack";
import ReviewPageSkeleton from "./Component/reviewPageSkeleton";
import { ReviewAlertBox } from "../Notification/NotificationAlertBox";
import { PATH_DASHBOARD } from "src/routes/paths";
import AlertMessages from "./Component/AlertMessages";
import useItemsTable from "./hooks/useItemsTable";
import { getDimensions } from "./hooks/useDimensions";
import { ReviewContext } from "./context/ReviewContext";
import { FormValidationProps } from "./types/types";
import { generateAreaMap } from "./hooks/useAreaMap";
import { TReviewContextType } from "./types/reviewContext";
import GeneralTable from "./Component/itemsModalTable/general/GeneralTable";
import useScroll from "./hooks/useScroll";
import DocumentOptionsButton from "./Component/DocumentOptionButton";
import { unifyItemFields } from "./helpers/unifyItems";
import SelfTrainingTagDialog from "./Component/SelfTrainingTagDialog";
import Multiply from "src/assets/icon/multiply/Multiply";
import { useTheme } from "@mui/material";
import { BreadCrumbs } from "./Component/BreadCrumbs";
import { TagLabel } from "./Component/AlertRemoveTag";
import SigningDetail, { SigningDetailState } from "./Component/SigningDetail";
import AccoutingPeriodDialog from "./Component/AccountingPeriodDialog";
import { setDoc, doc } from "firebase/firestore";
import useDefaultQuery from "../Documents/Finance/hooks/useDefaultQuery";

const FormValidation = ({
  approvalNotRequired,
  fileId,
  isModal = false,
  modalButtonLoading,
  setModalButtonLoading,
  ActionButtons = ({ isSubmitting }) => <></>,
  handleApprove = async () => {},
  readPermissions,
  writePermissions,
  handleClose,
}: FormValidationProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const ref = useRef<FormikProps<any>>(null);
  const gridRef = useRef<any>(null);
  const [messages, setMessages] = useState<
    {
      messageKey: string;
      values: string[] | null;
    }[]
  >([]);
  const {
    label: label_,
    initial,
    dimensions,
    base64String,
    expectedValues,
    reviewResponse,
    tag,
  } = useContext<TReviewContextType>(ReviewContext);
  const {
    base64String: base64Strings,
    imageloading,
    setBase64String,
    fetchBase64String,
  } = base64String;
  const { setDocumentFieldsExpectedValues } = expectedValues;
  const { initialState, setInitialState } = initial;
  const { width, height, setHeight, setWidth } = dimensions;
  const { setLabelColorMap } = label_;
  const { editOptionsProps, toolbarprops } = useReviewToolbar();
  const [data, setData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPageData, setCurrentPageData] = useState<any>([]);
  const {
    org: { enableAsyncReviewPage },
  } = store.getState()?.organization;
  const [state, setState] = useState({
    fileId: "",
    invoiceType: "",
  });
  const [openPostItems, setPostOpenItems] = useState(false);
  const { translate } = useLocales();
  const { currentTab, onChangeTab } = useTabs("0");
  const [postProcessing, setPostProcessing] = useState({});
  const [postTableItem, setPostTableItem] = useState([]);
  const [postTableTitle, setPostTableTitle] = useState("");
  const [transformedAxis, setTransformedAxis] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [documentSource, setDocumentSource] = useState("");
  const COMPANY_ID = localStorage.getItem("company_id");
  const { translateErrorMessage } = useErrorTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState(true);
  const [MAP, setMAP] = useState({ name: "my-map", areas: [] as any[] });
  const [confidenceArray, setConfidenceArray] = useState<any[]>([]);
  const location = useLocation();
  const [documentClass, setDocumentClass] = useState("");
  const [url, setUrl] = useState("");
  const { path: documentPath } = useDefaultQuery();
  const navigation = () => {
    if (location?.key != "default") navigate(-1);
    else navigate(documentPath);
  };
  const [documentTypeField, setDocumentTypeField] = useState<any>({});
  const [rows, setRows] = useState([]);
  const tableRefs = useRef<any>({});
  const [enableSelfTraining, setEnableSelfTraining] = useState(false);
  const [isPolygonUpdated, setIsPolygonUpdated] = useState(false);
  const [openAccoutingPeriod, setOpenAccountingPeriod] = useState(false);
  const [accountingPeriod, setAccountingPeriod] = useState<any>(null);
  const [documentIsDeleted, setDocumentIsDeleted] = useState(false);
  const {
    open,
    handleCloseItemsTable,
    handleOpenItemsTable,
    itemsListKey,
    formState,
    dispatch,
  } = useItemsTable({ values: ref?.current?.values || {} });
  const {
    axis,
    setAxis,
    boundingBox,
    xarrowColor,
    title,
    setZoom,
    zoom,
    prismaZoomRef,
    handleMouseScroll,
    removeArrowAxis,
    setXarrowColor,
    setBoundingBox,
    onMouseEnter,
    onMouseLeave,
  } = useScroll({ currentTab, tableRefs });
  const [tagName, setTagName] = useState<string>("");
  const [isJsonHashValid, setIsJsonHashValid] = useState(true);
  const [isValidHash, setIsValidHash] = useState(true);
  const [removeTagName, setRemoveTagName] = useState<string>("");
  const [signingDetail, setSigningDetail] = useState<SigningDetailState | null>(
    null
  );
  useEffect(() => {
    handleInvoiceDetail();
  }, []);

  function handlePostItemOpen(item, k: string) {
    setPostTableTitle(k);
    setPostTableItem(item);
    setPostOpenItems(true);
  }

  function handlePostItemsClose() {
    setPostTableTitle("");
    setPostTableItem([]);
    setPostOpenItems(false);
  }
  const TABS = [
    { value: "0", label: translate(`review_screen.extraction`) },
    { value: "1", label: translate(`review_screen.post_processing`) },
  ];

  const handleInvoiceDetail = async () => {
    setState((state) => ({
      ...state,
      fileId: fileId ?? "",
    }));
    if (fileId) {
      fetchData(fileId);
      let baseString = await fetchBase64String(fileId, 1, (response) => {
        setIsValidHash(response?.data?.isValidHash);
      });
      let base64Strings_ = base64Strings;
      base64Strings_[pageNo - 1] = baseString ?? "";
      setBase64String([...base64Strings_]);
    } else {
      setTimeout(() => {
        navigation();
      }, 0);
    }
  };

  const snackbar = (val: string, variant: VariantType, duration = 2000) => {
    setTimeout(() => {
      if (!isModal) {
        navigation();
      }
      enqueueSnackbar(`${val}`, { variant: variant, autoHideDuration: 7000 });
    }, duration);
  };

  const fetchData = async (fileId: string) => {
    setIsLoading(true);
    const response = await GetAsync(
      `${API_GATEWAY}/api/documentservice/DocumentReview/${fileId}`
    );
    try {
      if (response.status === 204) {
        snackbar("File is corrupted", "error");
      } else if ([400, 404].includes(response.status)) {
        snackbar(translate(response?.data?.Detail), "error");
        setTimeout(() => {
          navigation();
        }, 0);
      } else if (response.status === 500) {
        snackbar(translate("something_went_wrong"), "error");
        setTimeout(() => {
          navigation();
        }, 0);
      }
    } catch (err) {
      snackbar("File is corrupted", "error");
    }
    // let response = { data: res };
    if (response?.data?.accountingPeriod) {
      setAccountingPeriod(response?.data?.accountingPeriod);
    }
    setFileName(response?.data?.documentName);
    setMessages(response?.data?.messageKeys);
    setIsJsonHashValid(response?.data?.isJsonHashValid);
    setUrl(response?.data?.url);
    setDocumentIsDeleted(response?.data?.documentIsDeleted);
    const splittedFileType = response?.data?.documentName.split(".");
    setFileType(splittedFileType[1]);
    setDocumentSource(response?.data?.documentSource ?? "");
    let contentString = "";
    if (
      response?.data?.analyzedDocumentResult?.pages &&
      response?.data?.enableSelfTraining
    ) {
      contentString = tag.getContentString(
        response?.data?.analyzedDocumentResult?.pages
      );
      tag.setTagContentString(contentString);
    }
    if (response?.data?.enableSelfTraining && contentString) {
      setEnableSelfTraining(true);
    }
    if (response?.data?.analyzedDocumentResult?.document?.tagName) {
      setTagName(response?.data?.analyzedDocumentResult?.document?.tagName);
    }

    let data = response?.data?.analyzedDocumentResult?.pages;
    let data1 = response?.data?.analyzedDocumentResult?.document;
    setSigningDetail(response?.data?.signingDetail);
    let postProcessedJson = response?.data?.postProcessedJson?.Document;
    let [fields, metadata] = partition(
      data1?.fields,
      (field) => !field.isMetaDataValue
    );
    // hide DocumentType field
    let fieldsWithoutDocumentType = fields.filter((field) => {
      return field.name != "DocumentType";
    });
    let documentTypeField_ = fields?.find((field) => {
      return field.name == "DocumentType";
    });
    setDocumentTypeField(documentTypeField_);
    let fieldsUnifield = unifyItemFields(fieldsWithoutDocumentType);
    const labels = getLabeColor(fieldsUnifield);
    setLabelColorMap(labels);
    let fields_ = applyColorToLabel(labels, fieldsUnifield);
    let reviewRequiredFields = fields_.filter(
      (field) => field.isFieldReviewRequired
    );
    let documentClassField = fields_?.find(
      (field) => field.name === "DocumentClass"
    );
    if (documentClassField) {
      setDocumentClass(documentClassField.value);
    }
    let fieldsWithNullPolygonStatus = fields_.map((field) => {
      if (Array.isArray(field.itemsFields)) {
        field.itemsFields = field.itemsFields.map((item) => {
          item.item = item.item.map((it) => {
            it.polygonStatus = null;
            return it;
          });
          return item;
        });
      }
      field.polygonStatus = null;
      return field;
    });
    let fieldsWithMaxItemIndex = fieldsWithNullPolygonStatus.map((field) => {
      if (Array.isArray(field.itemsFields)) {
        let maxIndex = field.itemsFields?.length - 1;
        field.maxItemIndex = maxIndex;
      }
      return field;
    });
    setInitialState((state) => ({
      ...state,
      fields: fieldsWithMaxItemIndex,
      metaData: metadata,
      fileId: fileId,
      status: response?.data?.documentStatus,
      documentFieldsExpectedValues:
        response?.data?.documentFieldsExpectedValues,
    }));
    setDocumentFieldsExpectedValues(
      response?.data?.documentFieldsExpectedValues
    );
    setConfidenceArray(reviewRequiredFields);
    let fileExtension_ = response?.data?.documentContentType;
    setTotalPages(data?.length);
    setData(data);
    if (data?.length > 0) {
      setCurrentPageData(data?.[0]);
    }
    let { height: h, width: w } = getDimensions(
      data?.[0]?.height,
      data?.[0]?.width,
      isScreen
    );
    setPageData(data?.[0], { fields: fieldsWithMaxItemIndex }, 1, h, w);
    setHeight(h);
    setWidth(w);
    setState((state) => ({ ...state, invoiceType: fileExtension_ }));
    let postProcessedJsonMap: any = { general: [] };
    delete postProcessedJson?.["DocumentType"];
    Object.entries(postProcessedJson)?.forEach(([k, item], i) => {
      if (Array.isArray(item)) {
        let list: any[] = [];
        item?.forEach((it, i) => {
          let itemList: any[] = [];
          Object.entries(it)?.forEach(([k, v], ind) => {
            return itemList?.push({
              key: k,
              value: v,
              color:
                labels?.[k] ?? COLOR_LIST[Math.floor(Math.random() * 12)].value,
              ind: i,
            });
          });
          list.push(itemList);
        });
        postProcessedJsonMap[k] = list;
      } else {
        postProcessedJsonMap["general"] = [
          ...postProcessedJsonMap["general"],
          {
            key: k,
            value: item == null ? "" : item,
            color:
              labels?.[k] ?? COLOR_LIST[Math.floor(Math.random() * 12)].value,
          },
        ];
      }
    });
    setPostProcessing(postProcessedJsonMap);
    setIsLoading(false);
  };

  // check if polygon status is updated for atleast one field polygon
  const checkIfPolygonStatusIsUpdated = (fields) => {
    let isUpdated = false;
    fields?.forEach((field) => {
      if (field.polygonStatus != null) {
        isUpdated = true;
      }
      if (Array.isArray(field.itemsFields)) {
        field.itemsFields?.forEach((item) => {
          item.item?.forEach((it) => {
            if (it.polygonStatus != null) {
              isUpdated = true;
            }
          });
        });
      }
    });
    return isUpdated;
  };

  const onClickNext = () => {
    if (totalPages > pageNo) {
      let p = pageNo + 1;
      setPageData(data?.[p - 1], ref?.current?.values, p, height, width);
      setPageNo(p);
      setCurrentPageData(data?.[p - 1]);
    }
  };
  const onClickPrevious = () => {
    if (pageNo > 1) {
      let p = pageNo - 1;
      setPageNo(p);
      setCurrentPageData(data?.[p - 1]);
      setPageData(data?.[p - 1], ref?.current?.values, p, height, width);
    }
  };

  function getFieldValue(dotNotationPath, sourceObject = ref?.current?.values) {
    let returnData = sourceObject;
    dotNotationPath.split(".").forEach((subPath) => {
      returnData = returnData[subPath] || `Property ${subPath} not found`;
    });
    return returnData;
  }

  const onClick = (area, ind, evt) => {
    var startTime = performance.now();
    evt.preventDefault?.();
    setTimeout(() => {
      setAxis({
        xAxis: evt.pageX,
        yAxis: evt.pageY,
        xAxisDialog: evt.pageX,
        yAxisDialog: evt.pageY,
      });
      transform(evt.pageX, evt.pageY);
      setXarrowColor(area.strokeColor);
      setBoundingBox(area?.key || area);
      let key = area?.key;
      if (key) {
        let keys = key.split(".");
        let value = structuredClone(ref?.current?.values?.[keys[0]]?.[keys[1]]);
        let itemField = structuredClone(getFieldValue(key));
        ref?.current?.setFieldValue("area", {
          ...area,
          ind: ind,
          itemField: itemField,
          field: value,
          value: value,
          itemIndex: +keys?.[3] + 1 || 1,
        });
      } else {
        ref?.current?.setFieldValue("area", {
          ...area,
          ind: ind,
          itemField: null,
          field: {
            name: "un_labelled",
            pageNumber: area.pageNumber,
            value: area.title,
            boundingPolygon: area?.boundingPolygon,
          },
          value: {
            name: "un_labelled",
            value: area.title,
            boundingPolygon: area?.boundingPolygon,
          },
        });
      }
      var endTime = performance.now();
      console.log(
        `Call to doSomething took ${endTime - startTime} milliseconds`
      );
    }, 100);
  };

  const { state: requestState } = reviewResponse;

  const onSubmit = async (values, setSubmitting, tag?, removeTag?) => {
    let response: any;
    if (enableAsyncReviewPage) {
      let documentClass_ = documentClass;
      let field = requestState?.fields?.find(
        (field) => field.name === "DocumentClass"
      );
      if (field) {
        documentClass_ = field.value ?? "";
      }
      let data = requestState;
      let fields = data.fields;
      fields = fields.map((field) => {
        if (Array.isArray(field.itemsFields)) {
          field.itemsFields = field.itemsFields.filter(
            (item) => !(item.isRemoved && item.isNew)
          );
        }
        return field;
      });
      data.fields = fields;

      response = PutAsync(
        `${API_GATEWAY}/api/documentservice/v2/DocumentReview` +
          "/" +
          values?.fileId,
        {
          DocumentId: values.fileId,
          documentClass: documentClass_,
          NewTagName: tag == "" ? null : tag,
          ...data,
        }
      );
    } else {
      if (setModalButtonLoading) setModalButtonLoading(true);
      setSubmitting(true);
      if (removeTagName != "") {
        await DeleteAsync(
          `${API_GATEWAY}/api/extractionservice/selfTraining/tag`,
          {
            tagName: removeTag,
          }
        )
          .then((res) => {
            if (res.status === 404) {
              enqueueSnackbar(translate("Tag not found"), {
                variant: "error",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            enqueueSnackbar(translate("Failed to remove tag"), {
              variant: "error",
            });
          });
      }
      let fields = values.fields;
      let metaData = values?.metaData;
      let combinedfields = [documentTypeField, ...fields, ...metaData];
      let body = {
        DocumentId: values.fileId,
        fields: combinedfields,
        NewTagName: tag == "" ? null : tag,
      };
      response = PutAsync(
        `${API_GATEWAY}/api/documentservice/DocumentReview` +
          "/" +
          values?.fileId,
        body
      );
    }
    await response
      .then(async (response) => {
        if (response?.status === 204 || response?.status === 200) {
          snackbar("Updated Successfully", "success", 100);
          DocumentFinished({
            eventName: "Doc Reviewed",
            Id: values?.fileId,
            nameOfDocuments: fileName,
            typeOfDocuments: fileType,
            sourceOfDocuments: documentSource,
            status: true,
            organizationId: COMPANY_ID,
          });
          if (!approvalNotRequired) {
            await handleApprove();
          }
          setSubmitting(false);
          if (handleClose) handleClose();
        } else {
          snackbar(translateErrorMessage(response?.data?.Detail), "error");
          DocumentFinished({
            eventName: "Doc Reviewed",
            Id: values?.fileId,
            status: false,
          });
          setSubmitting(false);
        }
      })
      .catch((err) => {
        snackbar("Something went wrong", "error");
        DocumentFinished({
          eventName: "Doc Reviewed",
          Id: values?.fileId,
          status: false,
        });
        setSubmitting(false);
      });
  };

  const closeDialog = () => {
    setAxis((axis) => ({ ...axis, xAxisDialog: null, yAxisDialog: null }));
    setXarrowColor("");
    ref?.current?.setFieldValue("area", null);
    setTimeout(() => {
      setPageData(currentPageData, ref?.current?.values, pageNo, height, width);
    }, 100);
  };

  const handleScreenBool = () => {
    store.dispatch(setScreen(!isScreen));
    let { width: w, height: h } = getDimensions(
      currentPageData?.height,
      currentPageData?.width,
      store.getState()?.reviewScreen?.isScreen
    );
    setHeight(h);
    setWidth(w);
    setPageData(currentPageData, ref?.current?.values, pageNo, h, w);
    setIsScreen(store.getState()?.reviewScreen?.isScreen);
  };

  const [isScreen, setIsScreen] = useState(
    store.getState()?.reviewScreen?.isScreen
  );
  const { avgEvent, generateLine, resetState, gatherEvents } = editOptionsProps;
  const { editMode } = toolbarprops;
  const [alreadyLabelledAreas, setAlreadyLabelledAreas] = useState(new Map());
  const transform = (x, y) => {
    let rect = gridRef?.current?.getBoundingClientRect();
    let top = rect.top * 1.2;
    let left = rect.left * 1.2;
    let right = rect.right * 0.8;
    let bottom = rect.bottom * 0.8;
    let xt = "0%";
    let yt = "0%";
    if (x <= left) xt = "0%";
    if (x >= right) xt = "-100%";
    if (y <= top) yt = "0%";
    if (y >= bottom) yt = "-100%";
    setTransformedAxis(`translate(${xt}, ${yt})`);
  };
  let areas = [];

  const combineSelectedAreas = () => {
    let line = generateLine();
    let index = 0;
    Object.values(alreadyLabelledAreas)?.forEach((area) => {
      let field = getFieldValue(area.key);
      field.boundingPolygon = null;
      field.value = "";
      field.confidence = 0;
      ref?.current?.setFieldValue(area.key, field);
    });
    let box = createOcrBoundingBoxes(
      line,
      index,
      height,
      width,
      currentPageData?.height,
      currentPageData?.width
    );
    box.pageNumber = pageNo;
    let event = avgEvent();
    if (event.pageX !== 0 && event.pageY !== 0) {
      onClick(box, areas.length - 1, event);
    }
    resetState();
    setAlreadyLabelledAreas(new Map());
  };
  const setPageData = async (
    data,
    values,
    pageNo: number,
    height: number,
    width: number
  ) => {
    setCurrentPageData(data);
    if (pageNo > 1) {
      let baseString = await fetchBase64String(fileId, pageNo);
      let base64Strings_ = base64Strings;
      base64Strings_[pageNo - 1] = baseString ?? "";
      setBase64String([...base64Strings_]);
    }
    let labels = generateLabels(values?.fields);
    let map = generateAreaMap(values, pageNo, data, height, width, labels);
    setMAP(map.MAP);
  };
  const [generalTOpen, setGeneralTOpen] = useState(false);

  const handleItemOpenGeneral = (data) => {
    setRows(ref?.current?.values?.fields);
    setGeneralTOpen(true);
  };
  const handleItemCloseGeneral = () => {
    setGeneralTOpen(false);
    setRows([]);
  };
  const theme = useTheme();
  const [openTagDialog, setOpenTagDialog] = useState(false);
  if (!readPermissions) {
    return <Navigate to={"/403"} />;
  }
  return (
    <Page title="Review" id={"review-page"}>
      {!isModal && !isLoading && <BreadCrumbs />}
      {accountingPeriod && (
        <AccoutingPeriodDialog
          open={openAccoutingPeriod}
          handleClose={() => setOpenAccountingPeriod(false)}
          accountingPeriod={accountingPeriod}
          writePermissions={writePermissions}
          documentIsDeleted={documentIsDeleted}
        />
      )}
      <SelfTrainingTagDialog
        content={tag.tagContentString}
        open={openTagDialog}
        isPolygonUpdated={isPolygonUpdated}
        setOpenTagDialog={setOpenTagDialog}
        onSave={(tag) => {
          onSubmit(ref?.current?.values, setSubmitting, tag, removeTagName);
        }}
        loading={isSubmitting}
      />
      <GeneralTable
        open={generalTOpen}
        handleClose={handleItemCloseGeneral}
        rows={rows}
        setFieldValue={ref?.current?.setFieldValue}
      />

      <ItemsTableTable
        dispatch={dispatch}
        formState={formState}
        open={open}
        handleItemsClose={handleCloseItemsTable}
        itemsListKey={itemsListKey}
        setFieldValue={ref?.current?.setFieldValue}
      />
      <Formik
        initialValues={initialState}
        onSubmit={(values) => onSubmit(values, setSubmitting, tagName)}
        enableReinitialize={true}
        innerRef={ref}
      >
        {({ values, setFieldValue, setValues }) => (
          <Form>
            {postTableItem?.length > 0 && (
              <ItemsTable
                openItems={openPostItems}
                handleItemsClose={handlePostItemsClose}
                label={postTableItem}
                title={postTableTitle}
              />
            )}
            {axis.xAxis && axis.yAxis && boundingBox && (
              <div
                id="end1"
                style={{
                  position: "absolute",
                  top: `${axis.yAxis - 12}px`,
                  left: `${axis.xAxis}px`,
                }}
              ></div>
            )}
            {values.area && (
              <div
                id="dialogBox"
                style={{
                  transform: transformedAxis,
                  top: `${(axis?.yAxisDialog ?? 0) + 12}px`,
                  left: `${axis.xAxisDialog}px`,
                  background: "#FFFFFF",
                  position: "absolute",
                  ...dialogboxStyles,
                }}
              >
                <DialogBox
                  closeDialog={() => closeDialog()}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </div>
            )}
            <Box
              sx={{
                px: 2,
              }}
            >
              {alert && !isLoading && (
                <ReviewAlertBox
                  isJsonHashValid={isJsonHashValid}
                  isValidHash={isValidHash}
                  setAlert={setAlert}
                  isDocumentPeriodClosed={accountingPeriod?.isClosed}
                  documentIsDeleted={documentIsDeleted}
                  status={initialState?.status}
                  messages={messages}
                />
              )}
            </Box>

            {!isLoading ? (
              <>
                {!isModal ? (
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      px: 2,
                      py: 1,
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="h4" component="div">
                        {translate("Document review")}
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="subtitle1" color="text.greyLight">
                          {fileName}
                        </Typography>

                        {tagName && enableSelfTraining && (
                          <>
                            <Divider
                              orientation="vertical"
                              sx={{
                                height: "20px",
                                width: "20px",
                                color: "action.active",
                                transform: "rotate(270deg)",
                              }}
                            />
                            <TagLabel
                              tagName={tagName}
                              setTagName={setTagName}
                              removeTagName={removeTagName}
                              setRemoveTagName={setRemoveTagName}
                            />
                          </>
                        )}
                        {documentIsDeleted && (
                          <>
                            <Divider
                              orientation="vertical"
                              sx={{
                                height: "20px",
                                width: "20px",
                                color: "action.active",
                                transform: "rotate(270deg)",
                              }}
                            />
                            <Chip
                              variant="outlined"
                              size="small"
                              label={translate("Deleted")}
                              sx={{
                                borderRadius: "4px",
                                height: "20px",
                                fontSize: "10px",
                                bgcolor: "error.light",
                                border: `1px solid ${theme?.palette?.error?.dark}`,
                                pointerEvents: "auto",
                                color: theme?.palette?.error?.dark,
                              }}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                        }}
                      >
                        <DocumentOptionsButton
                          writePermissions={writePermissions}
                          id={fileId}
                          fileName={fileName}
                          url={url}
                          disablePeriod={accountingPeriod == null}
                          navigation={navigation}
                          setOpenAccountingPeriod={setOpenAccountingPeriod}
                          documentIsDeleted={documentIsDeleted}
                        />
                        <span>
                          <Button
                            type="button"
                            size="medium"
                            variant="outlined"
                            disabled={isSubmitting}
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            {translate("Back")}
                          </Button>
                        </span>
                        <span>
                          <LoadingButton
                            type={
                              enableSelfTraining && !tagName
                                ? "button"
                                : "submit"
                            }
                            size="medium"
                            disabled={
                              isSubmitting ||
                              !writePermissions ||
                              documentIsDeleted
                            }
                            variant="contained"
                            loading={isSubmitting}
                            onClick={() => {
                              let check = checkIfPolygonStatusIsUpdated(
                                values?.fields
                              );
                              if (check) {
                                setIsPolygonUpdated(true);
                              }
                              if (enableSelfTraining && !tagName) {
                                setOpenTagDialog(true);
                              }
                            }}
                          >
                            {translate("save")}
                          </LoadingButton>
                        </span>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      p: 3,
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="h4" component="div">
                        {translate("Document review")}
                      </Typography>
                      <Typography variant="h6" component="div">
                        {fileName}
                      </Typography>
                    </Box>
                    <Box>
                      <Box>
                        {approvalNotRequired ? (
                          <LoadingButton
                            type="submit"
                            size="medium"
                            variant="contained"
                            loading={isSubmitting}
                          >
                            {translate("save")}
                          </LoadingButton>
                        ) : (
                          ActionButtons(
                            <ActionButtons isSubmitting={modalButtonLoading} />
                          )
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    display: { md: "flex", sm: "none" },
                    px: 2,
                    background: "#80808003",
                  }}
                >
                  {xarrowColor &&
                    boundingBox &&
                    currentTab === "0" &&
                    !isScreen && (
                      <Xarrow
                        start={"end1"} //can be react ref
                        end={boundingBox} //or an id
                        zIndex={1}
                        // innerRef={arrowRef}
                        startAnchor={"right"}
                        showHead={false}
                        color={xarrowColor}
                        strokeWidth={2}
                        path="smooth"
                      />
                    )}
                  <Grid container spacing={1} id={"img-container"}>
                    <ReviewToolbarPanel
                      pages={
                        <>
                          {pageNo} {translate("review_screen.of")}{" "}
                          {totalPages > 0 ? totalPages : 1}
                        </>
                      }
                      combineSelectedAreas={combineSelectedAreas}
                      setAlreadyLabelledAreas={setAlreadyLabelledAreas}
                      editMode={editMode}
                      prismaZoomRef={prismaZoomRef}
                      zoom={zoom}
                      next={onClickNext}
                      previous={onClickPrevious}
                      setZoom={setZoom}
                      {...editOptionsProps}
                      handleScreenBool={handleScreenBool}
                      isScreen={isScreen}
                    />
                    <Grid
                      ref={gridRef}
                      id={"img-mapper-page"}
                      item
                      md={isScreen ? 12 : 7}
                      sx={{
                        maxHeight: "70vh",
                        cursor: "pointer",
                        overflow: "auto",
                      }}
                      key={pageNo - 1}
                    >
                      {currentPageData && MAP?.areas?.length > 0 ? (
                        <PageDataView
                          data={currentPageData}
                          base64String={base64Strings[pageNo - 1]}
                          imageLoading={imageloading[pageNo - 1]}
                          prismaZoomRef={prismaZoomRef}
                          values={values}
                          currentPage={pageNo}
                          onMouseEnter={onMouseEnter}
                          onMouseLeave={onMouseLeave}
                          onClick={onClick}
                          invoiceType={state.invoiceType}
                          setZoom={setZoom}
                          editOptionsProps={editOptionsProps}
                          toolbarprops={toolbarprops}
                          alreadyLabelledAreas={alreadyLabelledAreas}
                          gatherEvents={gatherEvents}
                          height={height}
                          width={width}
                          MAP={MAP}
                        />
                      ) : (
                        <XMLDataView
                          next={onClickNext}
                          previous={onClickPrevious}
                          values={values}
                          currentPage={pageNo}
                          onClick={onClick}
                          invoiceType={state.invoiceType}
                          setValues={setValues}
                        />
                      )}
                    </Grid>
                    {!isScreen && (
                      <Grid
                        id={"scrollToScreen"}
                        item
                        md={5}
                        sx={{
                          maxHeight: "70vh",
                          p: 2,
                          width: { md: "150px" },
                          overflow: "auto",
                        }}
                      >
                        <>
                          <Box>
                            {/* {initialState?.status == 4 && (
                              <AlertMessages messages={messages} />
                            )} */}
                            <Box
                              component={"div"}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {confidenceArray?.length > 0 ? (
                                <Typography variant={"h6"}>
                                  {translate("review_screen.review_required")}
                                </Typography>
                              ) : null}
                            </Box>
                            {confidenceArray?.map((item, index) => {
                              return (
                                <div key={index}>
                                  <div key={"confidence-" + index}>
                                    {item.isFieldReviewRequired && (
                                      <LowConfidence
                                        title={item.name}
                                        item={item}
                                        setFieldValue={setFieldValue}
                                        getFieldValue={getFieldValue}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </Box>
                          <Tabs
                            scrollButtons="auto"
                            value={currentTab}
                            onChange={onChangeTab}
                            sx={{
                              px: 1,
                              height: "10px",
                              bgcolor: "background.paper",
                            }}
                          >
                            {TABS.map((tab) => (
                              <Tab
                                key={tab.value}
                                value={tab.value}
                                label={tab.label}
                                disableRipple
                              />
                            ))}
                          </Tabs>
                          {currentTab == "0" ? (
                            <PreProcessed
                              values={values}
                              setFieldValue={setFieldValue}
                              fields={values.fields}
                              title={title}
                              handleMouseScroll={handleMouseScroll}
                              removeArrowAxis={removeArrowAxis}
                              handleItemOpen={handleOpenItemsTable}
                              handleItemOpenGeneral={handleItemOpenGeneral}
                              tableRefs={tableRefs}
                              generateMap={() => {
                                let labels = generateLabels(values?.fields);
                                let map = generateAreaMap(
                                  values,
                                  pageNo,
                                  data[pageNo - 1],
                                  height,
                                  width,
                                  labels
                                );
                                setMAP(map.MAP);
                              }}
                            />
                          ) : (
                            <PostProcessing
                              postProcessing={postProcessing}
                              handleItemOpen={handlePostItemOpen}
                              title={title}
                            />
                          )}
                        </>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </>
            ) : (
              <ReviewPageSkeleton />
            )}
          </Form>
        )}
      </Formik>
    </Page>
  );
};
export default FormValidation;
