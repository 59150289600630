import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import { SmallCloseIcon } from "src/assets/icon/close/Small";
import { Box } from "@mui/material";
import MyErrorHelperText from "../denseForm/ErrorHelperText";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    // marginTop: theme.spacing(2.5),
    fontSize: "19px",
  },
  // "placeholder + &": {
  //   color: "#657482",
  //   fontSize: "14px",
  // },
  "& .MuiInputBase-input": {
    borderRadius: "4px",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: "14px",
    height: "25px",
    padding: "5px 26px 5px 10px",
    color: "#657482",
    // marginTop: "3px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: theme.palette.secondary.main,
      boxShadow: `0 0 0 0.01rem ${theme.palette.secondary.main}`,
      borderRadius: "4px",
    },
  },
}));
export const BootstrapFormInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "4px",
    backgroundColor: theme.palette.background.paper,
    padding: "5px 26px 5px 10px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
    border: "1px solid #528ACC",
    boxShadow: `0 0 0 0.01rem ${theme.palette.secondary.main}`,
    color: "#657482",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: ["Public Sans"].join(","),
    lineHeight: "16px",
    fontSize: "14px",
    "&:focus": {
      border: "1px solid #528ACC",
      boxShadow: `0 0 0 0.01rem ${theme.palette.secondary.main}`,
      borderRadius: "4px",
    },
  },
}));

const NewTextField = React.forwardRef(
  (
    { sx = {}, helperText, InputLabelProps, InputProps, ...props }: any,
    ref
  ) => {
    const theme = useTheme();
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          alignItems: "flex-start",
          p: 0,
          gap: "4px",
        }}
      >
        {props.label && (
          <InputLabel
            htmlFor="demo-customized-textbox"
            sx={{
              fontSize: "12px",
              fontWeight: 700,
              // transform: "translate(0, 1.5px) scale(0.75)",
              color: theme.palette.action.active,
              ...InputLabelProps,
              ...{
                ...(props.disabled
                  ? { color: theme.palette.action.disabled }
                  : {}),
              },
            }}
          >
            {props.label}
          </InputLabel>
        )}
        <BootstrapInput
          sx={{
            "& .MuiInputBase-input": {
              "&::placeholder": {
                opacity: 0.5,
              },
              ...(props.error && {
                backgroundColor: theme.palette.error.light,
                borderColor: theme.palette.error.dark,
                color: theme.palette.error.dark,
                "& svg": {
                  color: theme.palette.error.dark,
                },
                "&::placeholder": {
                  opacity: 0.8,
                  color: theme.palette.error.dark,
                },
                "&:focus": {
                  borderColor: theme.palette.error.dark,
                  boxShadow: `0 0 0 0.01rem ${theme.palette.error.dark}`,
                },
                ...sx,
              }),
              ...(props.startAdornment && {
                padding: "5px 26px 5px 32px",
              }),
              ...(props.error && {
                "& svg": {
                  color: theme.palette.error.dark,
                },
              }),
              ...sx,
            },
            ...sx,
          }}
          id="demo-customized-textbox"
          endAdornment={
            props.endAdornment
              ? props.endAdornment
              : props.close && (
                  <SmallCloseIcon
                    sx={{
                      zIndex: 10,
                    }}
                  />
                )
          }
          onKeyDown={(e) => e.stopPropagation()}
          fullWidth
          {...props}
          error={!!props.error}
          ref={ref}
        />

        {props.error ? (
          <MyErrorHelperText error={!!props.error} helperText={helperText} />
        ) : null}
      </Box>
      // </div>
    );
  }
);
export const NewTextFormField = React.forwardRef(
  ({ sx = {}, helperText, InputLabelProps, ...props }: any, ref) => {
    const theme = useTheme();
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          p: 0,
          gap: "4px",
        }}
      >
        {props.label && (
          <InputLabel
            htmlFor="demo-customized-textbox"
            sx={{
              fontSize: "12px",
              fontWeight: 700,
              fontStyle: "normal",
              lineHeight: "12px",
              height: "12px",
              fontFamily: ["Inter", "Public Sans"].join(","),
              flexGrow: 1,
              flex: "none",
              order: 0,
              color: theme.palette.action.active,
              ...sx,
              ...InputLabelProps,
            }}
          >
            {props.label}
          </InputLabel>
        )}
        <BootstrapFormInput
          sx={{
            "& .MuiInputBase-input": {
              ...(props.error && {
                backgroundColor: theme.palette.error.light,
                borderColor: theme.palette.error.dark,
                color: theme.palette.error.dark,
                "&::placeholder": {
                  opacity: 1,
                  color: theme.palette.error.dark,
                },
                "&:focus": {
                  borderColor: theme.palette.error.dark,
                  boxShadow: `0 0 0 0.01rem ${theme.palette.error.dark}`,
                },
                ...sx,
              }),
              ...(props.startAdornment && {
                padding: "5px 26px 5px 32px",
              }),
              ...sx,
            },
          }}
          id="demo-customized-textbox"
          endAdornment={
            props.endAdornment
              ? props.endAdornment
              : props.close && (
                  <SmallCloseIcon
                    sx={{
                      zIndex: 10,
                    }}
                  />
                )
          }
          onKeyDown={(e) => e.stopPropagation()}
          fullWidth
          {...props}
          ref={ref}
        />
        <MyErrorHelperText error={props.error} helperText={helperText} />
      </Box>
    );
  }
);

export default NewTextField;
