import { Box, Button } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import useLocales from "src/hooks/useLocales";
import { MyTextField, ReviewAutoComplete } from "src/components/FormFields";
import { useContext, useState } from "react";
import Multiply from "src/assets/icon/multiply/Multiply";
import { ReviewContext } from "../context/ReviewContext";
import { ItemField, ItemTable } from "../types/review";

export default function DialogBox({ closeDialog, values, setFieldValue }) {
  const reviewContext = useContext(ReviewContext);
  const { reviewResponse } = reviewContext;
  const { dispatch } = reviewResponse;
  const { translate } = useLocales();
  const t = (key: string): string =>
    translate(`review_screen.dialog_box.${key}`);
  const t_ = (title: string): string => {
    let result = translate(`review_screen.review_fields.${title}`);
    if (result.indexOf("review_screen") == -1) {
      return result;
    }
    return title;
  };
  const [errors, setErrors] = useState({
    field: false,
    itemField: false,
    itemIndex: false,
  });

  const setFieldError = (key: string, value: boolean) => {
    setErrors({ ...errors, [key]: value });
  };

  function getFieldValue(dotNotationPath: string, sourceObject: any = values) {
    let returnData = sourceObject;
    dotNotationPath.split(".").forEach((subPath) => {
      returnData = returnData[subPath] ?? {};
    });
    return returnData;
  }

  const flipValues = (field: any, value: any) => {
    value.boundingPolygon = field.boundingPolygon;
    value.confidence = field.confidence;
    value.value = getFieldValue(generateKey());
    value.pageNumber = field.pageNumber;
    value.confidence = 0.9;
    field.boundingPolygon = null;
    field.value = "";
    field.confidence = 0;
    return [field, value];
  };

  const checkErrors = (errors: any) => {
    let flag = false;
    Object.entries(errors).find(([key, value]) => {
      if (value) flag = true;
    });
    return flag;
  };

  const save_ = (remove?: boolean) => {
    //check if remove is true, then select the unlabelled option
    if (remove) {
      values.area.value = { name: "un_labelled", value: "" };
    }
    // check if boundingPolygon are added, removed or modified
    // if polygons are added, or updated, set polygonStatus to 1
    // if polygons are removed, set polygonStatus to 2
    let polygonKey = ["lowerLeft", "lowerRight", "upperRight", "upperLeft"];
    let isItem = false;
    if (checkErrors(errors)) return;
    let err = errors;
    let itemField: ItemField | null = null;
    let field: ItemTable | null = null;
    let value: any | { itemsFields: any[] } = null;
    if (
      // either field is unlabelled or it is a header level field, means no itemsField
      values?.area?.key == values?.area?.field?.key ||
      values?.area?.key == ""
    ) {
      field = structuredClone(values?.area?.field); // field is header level field
    } else {
      field = structuredClone(getFieldValue(values?.area?.key)); // field is item level field
    }
    value = structuredClone(values?.area?.value); // initial value before modification

    // either field is already  item level field or the new selection belongs to item level field
    if (values?.area?.valueKey) {
      itemField = structuredClone(getFieldValue(values?.area?.valueKey));
      isItem = true;
    } else if (values?.area?.key?.split(".").length == 6) {
      itemField = structuredClone(getFieldValue(generateKey2()));
      isItem = true;
    }
    if (!value) {
      err.field = true;
      setFieldError("field", true);
    }
    if (!itemField && isItem) {
      err.itemField = true;
      setFieldError("itemField", true);
      if (!values?.area?.itemIndex) {
        err.itemIndex = true;
        setFieldError("itemIndex", true);
      }
    } else if (value?.itemsFields?.length > 0 && itemField == null) {
      err.itemField = true;
      setFieldError("itemField", true);
      if (!values?.area?.itemIndex) {
        err.itemIndex = true;
        setFieldError("itemIndex", true);
      }
    }
    if (checkErrors(err)) {
      setErrors({ ...err });
      return;
    }
    if (itemField != null && Object.keys(itemField).length != 0) {
      let initialFieldPolygon = field!.boundingPolygon;
      let initialItemFieldPolygon = itemField.boundingPolygon;
      let [field_, value_] = flipValues(field, itemField);
      polygonKey.forEach((key) => {
        ["x", "y"].forEach((k) => {
          if (
            initialFieldPolygon?.[key]?.[k] !=
              field_.boundingPolygon?.[key]?.[k] ||
            initialItemFieldPolygon?.[key]?.[k] !=
              value_.boundingPolygon?.[key]?.[k]
          ) {
            field_.polygonStatus = 1;
            value_.polygonStatus = 1;
          }
          if (initialFieldPolygon != null && field_.boundingPolygon == null) {
            field_.polygonStatus = 2;
          }
          if (
            initialItemFieldPolygon != null &&
            value_.boundingPolygon == null
          ) {
            value_.polygonStatus = 2;
          }
        });
      });
      setFieldValue(values?.area?.key, field_);
      setFieldValue(itemField.key, value_);
      let payload1: any = {};
      let payload2: any = {};
      if (field_.key?.split(".").length == 6) {
        payload1 = {
          name: field_.category,
          value: null,
          pageNumber: field_.pageNumber,
          confidence: null,
          isRemoved: false,
          isNew: false,
          itemsFields: [
            {
              index: getFieldValue(getItemIndex(field_.key)),
              isRemoved: false,
              item: [
                {
                  name: field_.name,
                  value: field_.value,
                  pageNumber: field_.pageNumber,
                  confidence: 0.9,
                  isRemoved: false,
                  isNew: false,
                  boundingPolygon: field_.boundingPolygon,
                },
              ],
            },
          ],
          boundingPolygon: null,
        };
      } else {
        payload1 = {
          name: field_.name,
          value: field_.value,
          pageNumber: field_.pageNumber,
          confidence: 0.9,
          isRemoved: false,
          isNew: false,
          itemsFields: null,
          boundingPolygon: field_.boundingPolygon,
        };
      }
      if (value_.key?.split(".").length == 6) {
        payload2 = {
          name: value_.category,
          value: null,
          pageNumber: value_.pageNumber,
          confidence: null,
          isRemoved: false,
          isNew: false,
          itemsFields: [
            {
              index: getFieldValue(getItemIndex(value_.key)),
              isRemoved: false,
              item: [
                {
                  name: value_.name,
                  value: value_.value,
                  pageNumber: value_.pageNumber,
                  confidence: 0.9,
                  isRemoved: false,
                  isNew: false,
                  boundingPolygon: value_.boundingPolygon,
                },
              ],
            },
          ],
          boundingPolygon: value_.boundingPolygon,
        };
      } else {
        payload2 = {
          name: value_.name,
          value: value_.value,
          pageNumber: value_.pageNumber,
          confidence: 0.9,
          isRemoved: false,
          isNew: false,
          itemsFields: null,
          boundingPolygon: value_.boundingPolygon,
        };
      }
      dispatch({
        type: "ADD_FIELD",
        payload: [payload1, payload2],
      });
    } else {
      let initialFieldPolygon = field!.boundingPolygon;
      let initialItemFieldPolygon = value.boundingPolygon;
      let [field_, value_] = flipValues(field, value);
      polygonKey.forEach((key) => {
        ["x", "y"].forEach((k) => {
          if (
            initialFieldPolygon?.[key]?.[k] !=
              field_.boundingPolygon?.[key]?.[k] ||
            initialItemFieldPolygon?.[key]?.[k] !=
              value_.boundingPolygon?.[key]?.[k]
          ) {
            field_.polygonStatus = 1;
            value_.polygonStatus = 1;
          }
          if (initialFieldPolygon != null && field_.boundingPolygon == null) {
            field_.polygonStatus = 2;
          }
          if (
            initialItemFieldPolygon != null &&
            value_.boundingPolygon == null
          ) {
            value_.polygonStatus = 2;
          }
        });
      });
      setFieldValue(values?.area?.key, field_);
      setFieldValue(value_.key, value_);
      let payload1 = {
        name: field_.name,
        value: field_.value,
        pageNumber: field_.pageNumber,
        confidence: 0.9,
        isRemoved: false,
        isNew: false,
        itemsFields: null,
        boundingPolygon: field_.boundingPolygon,
      };
      let payload2 = {
        name: value_.name,
        value: value_.value,
        pageNumber: value_.pageNumber,
        confidence: 0.9,
        isRemoved: false,
        isNew: false,
        itemsFields: null,
        boundingPolygon: value_.boundingPolygon,
      };
      dispatch({
        type: "ADD_FIELD",
        payload: [payload1, payload2],
      });
    }
    closeDialog();
  };
  const handleClickAway = () => {
    closeDialog();
  };

  const getItemsListOption = (area) => {
    let items = [];
    if (area?.value?.itemsFields?.length > 0) {
      if (
        area?.itemIndex &&
        area?.itemIndex <= area?.value?.itemsFields?.length
      ) {
        items = area?.value?.itemsFields?.[area?.itemIndex - 1]?.item;
      } else {
        items = area?.value?.itemsFields?.[0]?.item?.map((item) => {
          let k = item.key?.split(".");
          let newKey = `${k?.[0]}.${k?.[1]}.${k?.[2]}.${area.itemIndex - 1}.${
            k?.[4]
          }.${k?.[5]}`;
          return { ...item, id: null, key: newKey, confidence: 0, value: "" };
        });
      }
    } else if (area?.field?.itemsFields?.length > 0) {
      if (area?.itemIndex) {
        items = area?.field?.itemsFields?.[area?.itemIndex - 1]?.item;
      } else {
        items = area?.field?.itemsFields?.[0]?.item?.map((item) => {
          return { ...item };
        });
      }
    }
    return [...items, { name: "un_labelled", value: "" }];
  };

  const generateKey = () => {
    let [key0, key1, ...keys] = values?.area?.key?.split(".") ?? [];
    let key = "";
    if (keys?.length > 0) key = `area.field.` + keys.join(".") + ".value";
    else key = `area.field.value`;
    return key;
  };

  const generateKey2 = (key_ = values?.area?.key, string = "value") => {
    let [key0, key1, ...keys] = key_?.split(".") ?? [];
    let key = "";
    if (keys?.length > 0) key = `area.${string}.` + keys.join(".");
    else key = `area.${string}`;
    return key;
  };

  const getItemIndex = (key_ = values?.area?.key, string = "value") => {
    let [key0, key1, ...keys] = key_?.split(".") ?? [];
    let index = "";
    if (keys?.length > 0)
      index = `area.${string}.` + keys[0] + "." + keys[1] + ".index";
    return index;
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box>
          <Box
            component={"span"}
            onClick={closeDialog}
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: 16,
              right: 16,
              bgcolor: "#F3F3F3",
              borderRadius: "50%",
              padding: "4px",
              height: 30,
              width: 30,
              alignItems: "center",
              alignContent: "center",
              m: "auto",
              display: "flex",
              justifyContent: "center",
              "&:hover": { bgcolor: "#F3F3F3" },
            }}
          >
            <Multiply height={18} width={18} color="primary" />
          </Box>
          {values?.area?.value == null ||
          values.area?.value?.itemsFields == null ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                px: 2,
                mt: "2.8rem",
              }}
            >
              <ReviewAutoComplete
                name={"area.value"}
                t={t_}
                options={[
                  ...values?.fields,
                  { name: "un_labelled", value: "" },
                ]}
                displayKey="name"
                val={values?.area?.value}
                label={t("label")}
                itemDisplayOpts={{ showColor: true }}
                error={!!errors?.field}
                errorText={t("field_required")}
                helperText={`${translate("Field name")}: ${
                  values?.area?.value?.name
                    ? values?.area?.value?.name == "un_labelled"
                      ? ""
                      : values?.area?.value?.name
                    : ""
                }`}
                onChange={(_, v) => {
                  if (v) {
                    if (errors?.field) setFieldError("field", false);
                    setFieldValue("area.value", v);
                    if (values?.area?.itemsField) {
                      setFieldValue("area.itemField", null);
                    }
                  } else {
                    setFieldError("field", true);
                  }
                }}
              />
            </Box>
          ) : values?.area?.field?.name ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  mt: "2.8rem",
                  px: 2,
                  justifyContent: "center",
                }}
              >
                <ReviewAutoComplete
                  name={"area.value"}
                  t={t_}
                  options={[...values?.fields]}
                  displayKey="name"
                  label={t("label")}
                  itemDisplayOpts={{ showColor: true }}
                  error={!!errors?.field}
                  errorText={t("field_required")}
                  helperText={`${translate("Field name")}: ${
                    values?.area?.value?.name
                      ? values?.area?.value?.name == "un_labelled"
                        ? ""
                        : values?.area?.value?.name
                      : ""
                  }`}
                  val={values?.area?.value}
                  onChange={(_, v) => {
                    if (v) {
                      if (errors?.field) setFieldError("field", false);
                      let area = structuredClone(values?.area);
                      area.itemIndex = "";
                      area.value = v;
                      area.itemField = null;
                      setFieldValue("area", area);
                    } else {
                      setFieldError("field", true);
                    }
                  }}
                />
              </Box>
              <Box>
                <Box sx={{ px: 2, display: "flex", justifyContent: "center" }}>
                  <ReviewAutoComplete
                    name="area.itemIndex"
                    label={`Row number ( 1 - ${
                      values?.area?.value?.itemsFields?.length ??
                      values?.area?.field?.itemsFields?.length
                    } )`}
                    displayKey={"label"}
                    t={t_}
                    error={!!errors?.itemIndex}
                    errorText={t("itemindex_required")}
                    val={
                      values?.area?.itemIndex
                        ? {
                            value: values?.area?.itemIndex,
                            label: `${t("row")} ${values?.area?.itemIndex}`,
                          }
                        : null
                    }
                    options={Array.from(
                      Array(
                        values?.area?.value?.itemsFields?.length ??
                          values?.area?.field?.itemsFields?.length
                      ).keys()
                    ).map((i) => {
                      return { value: i + 1, label: `${t("row")} ${i + 1}` };
                    })}
                    onChange={(e, v) => {
                      if (v) {
                        if (errors?.itemIndex)
                          setFieldError("itemIndex", false);
                        setFieldValue("area.itemIndex", v.value);
                        setFieldValue("area.itemField", null);
                      } else {
                        setFieldError("itemIndex", true);
                      }
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <ReviewAutoComplete
                  name={"area.itemField"}
                  t={t_}
                  val={values?.area?.itemField}
                  error={!!errors?.itemField}
                  errorText={t("item_required")}
                  itemDisplayOpts={{ showColor: true }}
                  options={getItemsListOption(values?.area) || []}
                  displayKey="name"
                  disabled={
                    values.area?.itemIndex == "" ||
                    (values?.area?.itemIndex >
                      values?.area?.value?.itemsFields?.length ??
                      values?.area?.field?.itemsFields?.length)
                  }
                  helperText={`${translate("Field name")}: ${
                    values?.area?.itemField?.name
                      ? values?.area?.itemField?.name == "un_labelled"
                        ? ""
                        : values?.area?.itemField?.name
                      : ""
                  }`}
                  label={t("label")}
                  onChange={(_, v) => {
                    if (v) {
                      if (errors?.itemField) setFieldError("itemField", false);
                      setFieldValue("area.itemField", v);
                      setFieldValue(generateKey2(v.key), v);
                      setFieldValue("area.valueKey", generateKey2(v.key));
                    } else {
                      setFieldError("itemField", true);
                    }
                  }}
                />
              </Box>
            </>
          ) : null}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <MyTextField
                name={
                  values?.area?.field?.itemsFields == null
                    ? "area.field.value"
                    : generateKey()
                }
                inputProps={{ min: 0, style: { textAlign: "center" } }}
                InputProps={{ style: { fontSize: "14px" } }}
                multiple={true}
                rows={4}
                label={t("value")}
                sx={{ width: 315, paddingTop: 0 }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "right",
              padding: "1rem",
              gap: 1,
            }}
          >
            <Button variant="outlined" onClick={() => save_(true)} size="small">
              {t("remove")}
            </Button>
            <Button variant="contained" onClick={() => save_()} size="small">
              {t("save")}
            </Button>
          </Box>
        </Box>
      </ClickAwayListener>
    </>
  );
}
